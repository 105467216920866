import React, { useEffect } from "react";
import DataTable from "../datatable";
import { useState } from "react";
import {
  PAGINATION_DEFAULT,
  reImbursementStatusConfig,
} from "../../utils/constants";
import {
  useDeclineReimbursementDisbursement,
  useDeleteReimbursement,
  useGetEmployeeReimbursements,
} from "../../redux/employees/hook/reimbursements";
import AddReImbursement from "../modal/employees/add-reimbursement";
import Button from "../button";
import ReimbursementApplications from "../modal/employees/reimbursement-applications";
import PayReimbursement from "../modal/employees/pay-reimbursement";
import SelectReImbursementOption from "../modal/employees/select-reimbursement-option";
import DoYouWantToModal from "../modal/do-you-want-to";
import ReimbursementDetailedView from "../modal/employees/view-reimbursement-payment";
import { store } from "../../redux";
import { setAlert } from "../../redux/components/components-slice";
import HelpSVG from "../../assets/svg/help.svg";
import InfoModal from "../modal/info-modal";
import { useGetEmployees } from "../../redux/employees/hook";

const EmployeeReimbursementTab = () => {
  useEffect(() => {}, []);
  const { data: employees } = useGetEmployees(9, 1, -1, "");
  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [showPay, setShowPay] = useState(false);

  const [applicationPay, setApplicationPay] = useState(false);

  const [reimbursementData, setReimbursementData] = useState();

  const [showReimbursementApplications, setShowReimbursementApplications] =
    useState(false);
  const [showSelectReimbursementOptions, setShowSelectReimbursementOptions] =
    useState(false);

  const [showReimbursementPayment, setShowReimbursementPayment] =
    useState(false);
  const [viewData, setViewData] = useState(null);

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: declineReimbursement } =
    useDeclineReimbursementDisbursement();

  const { refetch, data: employeeReimbursements } =
    useGetEmployeeReimbursements(
      pagination?.limit,
      pagination?.page,
      pagination?.statusFilter,
      pagination?.search
    );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { mutateAsync: deleteReimbursement, isLoading: deleteLoading } =
    useDeleteReimbursement();

  const [showAddReImbursement, setShowAddReImbursement] = useState(false);
  const [reimbData, setReimbData] = useState();
  const [title, setTitle] = useState(null);
  const [buttonText, setButtonText] = useState(null);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Phone Number",
      "Purpose",
      "Type",
      "Month",
      "Amount",
      "Status",
    ],
    keys: [
      "employeeName",
      "phone",
      "expenses_description",
      "typeLabel",
      "formatted_month",
      "formatted_expenses_amount",
      "status",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "Status",
        key: "status",
      },
    },
  };

  const deleteSingleReimbursement = async (id) => {
    let index = employeeReimbursements.data.findIndex((item) => item.id === id);
    await deleteReimbursement(employeeReimbursements.data[index].id).then(
      () => {
        setShowDoYouWant(false);
      }
    );
  };

  const actionConfig = [
    {
      name: "View/Edit",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setButtonText("Update");
        setTitle("Update Reimbursement");
        setReimbData(employeeReimbursements.data[index]);
        setShowAddReImbursement(true);
      },
    },
    {
      name: "Pay Now",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setReimbursementData(employeeReimbursements.data[index]);
        setApplicationPay(false);
        setShowPay(true);
      },
    },
    {
      name: "Approve Payment",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setReimbursementData(employeeReimbursements.data[index]);
        setApplicationPay(true);
        setShowPay(true);
      },
    },
    {
      name: "Decline Payment",
      color: "red",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        declineReimbursement(employeeReimbursements.data[index].id);
      },
    },
    {
      name: "Delete",
      color: "red",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        setDeleteId(employeeReimbursements.data[index].id);
        setShowDoYouWant(true);
      },
    },
    {
      name: "View Payment",
      color: "green",
      action: (id) => {
        let index = employeeReimbursements.data.findIndex(
          (item) => item.id === id
        );
        if (employeeReimbursements.data[index].payment) {
          setViewData(employeeReimbursements.data[index].payment);
          setShowReimbursementPayment(true);
        } else {
          store.dispatch(setAlert(true, "warning", "No Payment Found"));
        }
      },
    },
  ];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center justify-end w-full">
        <img
          className="animate-bounce cursor-pointer hover:animate-none hover:scale-110 duration-300"
          onClick={() => {
            setShowInfoModal(true);
          }}
          src={HelpSVG}
          alt="help!"
          width={"40px"}
          height={"10px"}
        />
      </div>
      <div className="flex flex-col bg-white rounded border">
        {showDoYouWant ? (
          <DoYouWantToModal
            isOpen={showDoYouWant}
            closeModal={() => setShowDoYouWant(false)}
            id={deleteId}
            loading={deleteLoading}
            title={"Are you sure you want to delete this reimbursement?"}
            buttonText={"DELETE"}
            btnFunction={deleteSingleReimbursement}
          />
        ) : null}
        {showPay ? (
          <PayReimbursement
            isOpen={showPay}
            closeModal={() => setShowPay(false)}
            applicationPay={applicationPay}
            reimbursement={reimbursementData}
          />
        ) : null}
        {showReimbursementApplications ? (
          <ReimbursementApplications
            isOpen={showReimbursementApplications}
            closeModal={() => setShowReimbursementApplications(false)}
          />
        ) : null}
        {showAddReImbursement ? (
          <AddReImbursement
            isOpen={showAddReImbursement}
            closeModal={() => {
              setShowAddReImbursement(false);
            }}
            reimbData={reimbData}
            title={title}
            buttonText={buttonText}
          />
        ) : null}
        {showSelectReimbursementOptions ? (
          <SelectReImbursementOption
            isOpen={showSelectReimbursementOptions}
            closeModal={() => setShowSelectReimbursementOptions(false)}
          />
        ) : null}
        {showReimbursementPayment ? (
          <ReimbursementDetailedView
            isOpen={showReimbursementPayment}
            viewData={viewData}
            closeModal={() => setShowReimbursementPayment(false)}
          />
        ) : null}
        {showInfoModal ? (
          <InfoModal
            isOpen={showInfoModal}
            type={"reimbursement"}
            closeModal={() => setShowInfoModal(false)}
          />
        ) : null}
        <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
          <div className="md:flex hidden md:justify-start justify-center p3-bold header-4 md:w-[40%] w-full">
          Reimbursement
          </div>
          <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
            <div className="md:w-fit w-[100%]">
              <Button
                text="New Expense Claim"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"add"}
                disabled={!employees}
                onClick={() => {
                  setShowSelectReimbursementOptions(true);
                }}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="View Applications"
                type="button"
                theme={"primary"}
                leftIcon={"view"}
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                disabled={!employees}
                onClick={() => setShowReimbursementApplications(true)}
              />
            </div>
            <div className="md:w-fit w-[100%]">
              <Button
                text="Refetch"
                type="button"
                theme="primary"
                className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                textClass={"!text-[11px]"}
                leftIcon={"change"}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          </div>
        </div>
        <hr className="divider mt-2 mb-1" />
        <DataTable
          data={employeeReimbursements}
          tableConfig={tableConfig}
          actionConfig={actionConfig}
          updatePagination={(data) => setPagination(data)}
          searchData={true}
          statusConfig={reImbursementStatusConfig}
          filterByStatus={true}
          computedActions={true}
          noCheck={true}
        />
      </div>
    </div>
  );
};

export default EmployeeReimbursementTab;
