import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { useLogin } from '../../redux/user/hook';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useSelector } from 'react-redux';
import { setAlert } from '../../redux/components/components-slice';
import { store } from '../../redux';
import GoogleImage from'../../assets/img/google-play-badge.png';
import AppleImage from'../../assets/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const [loginSection, setloginSection] = useState('payroll');

  const { section } = useSelector((state) => state.sectionSlice);

  useEffect(() => {
    if (section) {
      if (section.name == 'core hr') {
        setloginSection('core hr');
      } else if (section.name == 'spend management') {
        setloginSection('spend management');
      }
    }
  }, [section]);

  const { mutate: signIn, isLoading: loading } = useLogin();
  const { webToken } = useSelector((state) => state.userSlice);

  useEffect(() => {}, []);

  const submitForm = async (data) => {
    if (loginSection) {
      const email = DOMPurify.sanitize(data?.email);
      const password = DOMPurify.sanitize(data?.password);
      const source = 'web';
      const web_token = webToken ? webToken : 'no_token';

      const requestData = {
        email,
        password,
        section: loginSection,
        source,
        web_token,
      };
      signIn(requestData);
    } else {
      store.dispatch(setAlert(true, 'info', 'Select a module to log in'));
    }
  };

  return (
    <AuthLayout>
      <>
        <form
          className='form md:px-4 min-h-full flex-col justify-center items-center'
          onSubmit={handleSubmit(submitForm)}
        >
          <div className='w-full text-center flex flex-col pb-[15px] items-center'>
            <span
              className={'header-3-regular md:header-2 text-secondary-2 pb-1'}
            >
              Welcome Back
            </span>
            <span
              className={'header-4 text-secondary-2 pb-1'}
            >
              Employer service portal
            </span>
          </div>
          <div className='flex flex-col gap-2 p-2 border bg-secondary-2-extralight rounded-md'>
            <div className='text-secondary-2 font-semibold flex justify-center w-full '>Login via mobile app</div>
            <div className='flex flex-row gap-2 w-full px-3 mt-2'>
              <a href="https://play.google.com/store/apps/details?id=com.ropay.app" className='mb-1' target='_blank'>
                  <img width="220" src={GoogleImage} alt="android"/>
              </a>
              <a href="https://apps.apple.com/ng/app/ropay-mobile/id1673798586" className='mt-[11px]' target='_blank'>
                  <img width="220" src={AppleImage} alt="ios"/>
              </a>
            </div>
          </div>

          <div className='flex justify-center items-center flex-row mt-[10px] font-bold text-[15px] mb-[10px]'>
            <div className='bg-color-border w-[100%] h-[1px] mr-[6px]'></div>
            <p className='!text-[18px]'>or</p>
            <div className='bg-color-border w-[100%] h-[1px] ml-[6px]'></div>
          </div>
          
          <FormInput
            label=''
            placeholder={'Email Address'}
            name='email'
            type='text'
            inputRef={register(formValidation('email', true))}
            readOnly={loading}
            error={errors.email}
            errorMessage={errors.email && errors.email.message}
          />
          <FormInput
            label=''
            placeholder={'Password'}
            name='password'
            type='password'
            inputRef={register(formValidation('password', true))}
            readOnly={loading}
            error={errors.password}
            errorMessage={errors.password && errors.password.message}
          />
          <div className='flex justify-between pb-[3px] pt-[3px] text-[13px] w-full'>
            <FormInput
              label='Remember me'
              name='remember'
              type='checkbox'
              inputRef={register(formValidation('checkbox'))}
              className={'!text-[13px]'}
              readOnly={loading}
              error={errors.remember}
              errorMessage={errors.remember && errors.remember.message}
            />
            <div className='flex justify-end cursor-pointer hover:underline text-[14px] font-extrabold w-1/2'>
              <Link to='/forgot'>Forgot Password?</Link>
            </div>
          </div>
          <Button
            text='Sign in'
            type='submit'
            loading={loading}
            disabled={loading}
          />
        </form>
        <span className='flex justify-center text-[15px] text-color-gray mt-3'>
          Don’t have an account?&ensp;
          <Link to='/register' className='hover:underline duration-300'>
            <span className='text-[15px] font-extrabold'>Sign Up</span>
          </Link>
        </span>
      </>
    </AuthLayout>
  );
};

export default SignInPage;
