
import HomeSVG from '../../assets/svg/sidebar/home-white.svg';
import EmployeeSVG from '../../assets/svg/sidebar/employees-white.svg';
import PayrollSVG from '../../assets/svg/sidebar/payroll-white.svg';
import AirtimeSVG from '../../assets/svg/sidebar/airtime-white.svg';
import WalletSVG from '../../assets/svg/sidebar/wallet-white.svg';
import DocumentSVG from '../../assets/svg/sidebar/document-white.svg';
import SettingsSVG from '../../assets/svg/sidebar/settings-white.svg';
import RecruitmentSVG from '../../assets/svg/sidebar/recruitment-white.svg';
import BranchesSVG from '../../assets/svg/sidebar/branch-white.svg';
import PerformanceSVG from '../../assets/svg/sidebar/performance-white.svg';
import StatutorySVG from '../../assets/svg/sidebar/statutory-white.svg';
import LeaveSVG from '../../assets/svg/sidebar/leave-white.svg';
import AttendanceSVG from '../../assets/svg/sidebar/attendance-white.svg';
import VendorSVG from '../../assets/svg/sidebar/vendor-white.svg';
import TeamSVG from '../../assets/svg/sidebar/teams-white.svg';
import LogoutSVG from '../../assets/svg/sidebar/logout-white.svg';
import DepartmentSVG from '../../assets/svg/sidebar/department-white.svg';
import OnboardingSVG from '../../assets/svg/sidebar/employee-onboarding.svg';
import ExitSVG from '../../assets/svg/sidebar/employee-exit.svg';
import PromotionSVG from '../../assets/svg/sidebar/promotions.svg';
import TrainingSVG from '../../assets/svg/sidebar/training.svg';
import FolderSVG from '../../assets/svg/sidebar/folder.svg';
import CustomerSVG from '../../assets/svg/sidebar/customer.svg';
import ChatSVG from '../../assets/svg/sidebar/chat.svg';
import ReferrralSVG from '../../assets/svg/sidebar/referral.svg';
import BenefitsSVG from '../../assets/svg/sidebar/employee-benefit.svg';

export const Sections = [
  {
    name: 'payroll',
    entryRoute: '/dashboard',
    sidebarItems: [
      {
        name: 'Dashboard',
        id: 'dashboard',
        icon: HomeSVG,
        route: '/dashboard',
        active: true,
        
      },
      {
        name: 'Employees',
        id: 'employees',
        icon: EmployeeSVG,
        setsubroute: false,
        paths: ['employees', 'salaries', 'payroll-groups', 'bonuses', 'overtime', 'advances-loans', 'other-deductions', 'reimbursements',],
        subroute: [
          {
            name: 'Employees Overview',
            id: 'employee-overview',
            icon: HomeSVG,
            route: '/employees',
            active: false,
          },
          {
            name: 'Salaries',
            id: 'salaries',
            icon: HomeSVG,
            route: '/salaries',
            active: false,
          },
          {
            name: 'Payroll Groups',
            id: 'payroll-groups',
            icon: HomeSVG,
            route: '/payroll-groups',
            active: false,
          },
          {
            name: 'Bonuses',
            id: 'bonuses',
            icon: HomeSVG,
            route: '/bonuses',
            active: false,
          },
          {
            name: 'Overtime',
            id: 'overtime',
            icon: HomeSVG,
            route: '/overtime',
            active: false,
          },
          {
            name: 'Advances/Loans',
            id: 'advances-loans',
            icon: HomeSVG,
            route: '/advances-loans',
            active: false,
          },
          {
            name: 'Other Deductions',
            id: 'other-deductions',
            icon: HomeSVG,
            route: '/other-deductions',
            active: false,
          },
          {
            name: 'Reimbursement',
            id: 'reimbursements',
            icon: HomeSVG,
            route: '/reimbursements',
            active: false,
          },
          {
            name: 'Suspensions',
            id: 'suspensions',
            icon: HomeSVG,
            route: '/suspensions',
            active: false,
          },
        ]
      },
      {
        name: 'Employee Benefits',
        icon: BenefitsSVG,
        route: '/employee-benefits',
        status: 'new',
        setsubroute: false,
        paths: ['employee-benefits'],
        subroute: [
          {
            name: 'HMO',
            id: 'hmo',
            route: '/employee-benefits/hmo',
            active: false,
          },
          {
            name: 'Transportation',
            id: 'transportation',
            route: '/employee-benefits/transportation',
            active: false,
          },
        ]
      },
      {
        name: 'Payroll',
        id: 'payroll',
        icon: PayrollSVG,
        route: '/payroll',
        
      },
      {
        name: 'Statutory Payments',
        id: 'statutory',
        icon: StatutorySVG,
        setsubroute: false,
        paths: ['statutory-payments'],
        subroute: [
          {
            name: 'PAYE Taxes',
            id: 'taxes',
            route: '/statutory-payments/taxes',
            active: false,
          },
          {
            name: 'Pensions',
            id: 'pensions',
            route: '/statutory-payments/pensions',
            active: false,
          },
          {
            name: 'NHF',
            id: 'nhf',
            route: '/statutory-payments/nhf',
            active: false,
          },
          {
            name: 'NHIS',
            id: 'nhis',
            route: '/statutory-payments/nhis',
            active: false,
          },
          {
            name: 'NSITF',
            id: 'nsitf',
            route: '/statutory-payments/nsitf',
            active: false,
          },
          {
            name: 'ITF',
            id: 'itf',
            route: '/statutory-payments/itf',
            active: false,
          },
        ]
      },
      // {
      //   name: 'Ro-chat',
      //   icon: ChatSVG,
      //   route: '/ro-chat',
      //   status: 'new'
      // },
      {
        name: 'Airtime',
        id: 'airtime',
        icon: AirtimeSVG,
        route: '/airtime',
      },
      
      {
        name: 'Company Documents',
        id: 'documents',
        icon: DocumentSVG,
        route: '/document',
      },
      {
        name: 'Reports',
        id: 'reports',
        icon: DocumentSVG,
        route: '/payroll-reports',
      },
      {
        name: 'Wallet',
        id: 'wallet',
        icon: WalletSVG,
        route: '/wallet',
      },
      {
        name: 'Settings',
        id: 'settings',
        icon: SettingsSVG,
        setsubroute: false,
        paths: ['settings'],
        subroute: [
          {
            name: 'Admin Management',
            id: 'admin-management',
            icon: HomeSVG,
            route: '/settings/admin-management',
            active: false,
          },
          {
            name: 'Company Information',
            id: 'company-information',
            icon: HomeSVG,
            route: '/settings/company-information',
            active: false,
          },
          {
            name: 'Payroll Settings',
            id: 'payroll-settings',
            icon: HomeSVG,
            route: '/settings/payroll-settings',
            active: false,
          },
          {
            name: 'Approval Settings',
            id: 'approval-settings',
            icon: HomeSVG,
            route: '/settings/approval-settings',
            active: false,
          },
          {
            name: 'Plans & Billings',
            id: 'plans-billings',
            icon: HomeSVG,
            route: '/settings/plans-billings',
            active: false,
          },
          {
            name: 'Integrations',
            id: 'integrations',
            icon: HomeSVG,
            route: '/settings/integrations',
            active: false,
          },
        ]
      },
      {
        name: 'Referrals',
        id: 'referrals',
        icon: ReferrralSVG,
        status: 'new',
        route: '/referrals',
      },
      {
        name: 'Logout',
        icon: LogoutSVG,
        route: '/logout',
      },
    ],
  },
  {
    name: 'core hr',
    entryRoute: '/dashboard',
    sidebarItems: [
      {
        name: 'Dashboard',
        icon: HomeSVG,
        route: '/dashboard',
      },
      {
        name: 'Recruitment',
        icon: RecruitmentSVG,
        route: '/recruitment',
      },
      {
        name: 'Employees',
        icon: EmployeeSVG,
        route: '/employees',
        setsubroute: false,
        paths: ['employees', 'salaries', 'guarantors', 'queries', 'probations', 'performance-improvement-plans', 'employee-documents', 'suspensions',],
        subroute: [
          {
            name: 'Employees Overview',
            id: 'employees',
            icon: HomeSVG,
            route: '/employees',
            active: false,
          },
          {
            name: 'Salaries',
            id: 'salaries',
            icon: HomeSVG,
            route: '/salaries',
            active: false,
          },
          {
            name: 'Guarantors',
            id: 'guarantors',
            icon: HomeSVG,
            route: '/guarantors',
            active: false,
          },
          {
            name: 'Queries',
            id: 'queries',
            icon: HomeSVG,
            route: '/queries',
            active: false,
          },
          {
            name: 'Probations',
            id: 'probations',
            icon: HomeSVG,
            route: '/probations',
            active: false,
          },
          {
            name: 'Performance Improvement Plans',
            id: 'performance-improvement-plans',
            icon: HomeSVG,
            route: '/performance-improvement-plans',
            active: false,
          },
          {
            name: 'Employee Documents',
            id: 'employee-documents',
            icon: HomeSVG,
            route: '/employee-documents',
            active: false,
          },
          {
            name: 'Suspensions',
            id: 'reimbursements',
            icon: HomeSVG,
            route: '/suspensions',
            active: false,
          },
        ]
      },
      {
        name: 'Employee Cabinet',
        icon: FolderSVG,
        route: '/employee-cabinet',
      },
      {
        name: 'Employee Onboarding',
        icon: OnboardingSVG,
        route: '/onboarding',
      },
      {
        name: 'Ro-chat',
        icon: ChatSVG,
        route: '/ro-chat',
        status: 'new',
        setsubroute: false,
        paths: ['ro-chat'],
        subroute: [
          {
            name: 'Chats',
            id: 'chats',
            route: '/ro-chat/chats',
            active: false,
          },
          {
            name: 'Settings',
            id: 'chat-settings',
            route: '/ro-chat/settings',
            active: false,
          },
        ]
      },
      {
        name: 'Employee Benefits',
        icon: BenefitsSVG,
        route: '/employee-benefits',
        status: 'new',
        setsubroute: false,
        paths: ['employee-benefits'],
        subroute: [
          {
            name: 'HMO',
            id: 'hmo',
            route: '/employee-benefits/hmo',
            active: false,
          },
          {
            name: 'Transportation',
            id: 'transportation',
            route: '/employee-benefits/transportation',
            active: false,
          },
        ]
      },
      {
        name: 'Attendance',
        icon: AttendanceSVG,
        setsubroute: false,
        paths: ['attendance'],
        subroute: [
          {
            name: 'Summary',
            id: 'attendance-summary',
            route: '/attendance/summary',
            active: false,
          },
          {
            name: 'Analysis',
            id: 'attendance-analysis',
            route: '/attendance/analysis',
            active: false,
          },
          {
            name: 'Schedule',
            id: 'attendance-schedule',
            route: '/attendance/schedule',
            active: false,
          },
          {
            name: 'Settings',
            id: 'attendance-settings',
            route: '/attendance/settings',
            active: false,
          },
        ]
      },
      {
        name: 'Leave',
        icon: LeaveSVG,
        route: '/leave',
        setsubroute: false,
        paths: ['leave'],
        subroute: [
          {
            name: 'Leave Applications',
            id: 'applications',
            route: '/leave/applications',
            active: false,
          },
          {
            name: 'Leave Types',
            id: 'types',
            route: '/leave/types',
            active: false,
          },
          {
            name: 'Leave Settings',
            id: 'settings',
            route: '/leave/settings',
            active: false,
          },
        ]
      },
      {
        name: 'Promotion',
        icon: PromotionSVG,
        route: '/promotion',
      },
      {
        name: 'Training',
        icon: TrainingSVG,
        route: '/training',
      },
      {
        name: 'Performance',
        icon: PerformanceSVG,
        route: '/performance',
        setsubroute: false,
        paths: ['performance'],
        subroute: [
          {
            name: 'Evaluations',
            id: 'evaluations',
            icon: HomeSVG,
            route: '/performance/evaluations',
            active: false,
          },
          {
            name: 'Analysis',
            id: 'analysis',
            icon: HomeSVG,
            route: '/performance/performance-analysis',
            active: false,
          },
          {
            name: 'Basic Performance Criteria',
            id: 'basic-performance-criteria',
            icon: HomeSVG,
            route: '/performance/basic-performance-criteria',
            active: false,
          },
          {
            name: 'Organisational Criteria',
            id: 'organisational-criteria',
            icon: HomeSVG,
            route: '/performance/organisational-criteria',
            active: false,
          },
          {
            name: 'Objectives and Key Results',
            id: 'okrs',
            icon: HomeSVG,
            route: '/performance/okrs',
            active: false,
          },
          {
            name: 'SMART Goals',
            id: 'smart-goals',
            icon: HomeSVG,
            route: '/performance/smart-goals',
            active: false,
          },
          {
            name: 'Balanced Scorecard',
            id: 'balanced-scorecard',
            icon: HomeSVG,
            route: '/performance/balanced-scorecard',
            active: false,
          },
          // {
          //   name: '360 Evaluation',
          //   id: '360-evaluation',
          //   icon: HomeSVG,
          //   route: '/performance/360-evaluation',
          //   active: false,
          // },
          {
            name: 'Technical Performance Criteria',
            id: 'technical-criteria',
            icon: HomeSVG,
            route: '/performance/technical-criteria',
            active: false,
          },
        ]
      },
      {
        name: 'Teams',
        icon: TeamSVG,
        route: '/team',
      },
      {
        name: 'Departments',
        icon: DepartmentSVG,
        route: '/department',
      },
      {
        name: 'Branches',
        icon: BranchesSVG,
        route: '/branch',
      },
      {
        name: 'Employee Exit',
        icon: ExitSVG,
        route: '/exit',
      },
      {
        name: 'Company Documents',
        icon: DocumentSVG,
        route: '/document',
      },
      // {
      //   name: 'Reports',
      //   id: 'reports',
      //   icon: DocumentSVG,
      //   route: '/people-reports',
      // },
      {
        name: 'Wallet',
        id: 'wallet',
        icon: WalletSVG,
        route: '/wallet',
      },
      {
        name: 'Settings',
        icon: SettingsSVG,
        route: '/settings',
        paths: ['settings'],
        setsubroute: false,
        subroute: [
          {
            name: 'Admin Management',
            id: 'admin-management',
            icon: HomeSVG,
            route: '/settings/admin-management',
            active: false,
          },
          {
            name: 'Company Information',
            id: 'company-information',
            icon: HomeSVG,
            route: '/settings/company-information',
            active: false,
          },
          {
            name: 'Payroll Settings',
            id: 'payroll-settings',
            icon: HomeSVG,
            route: '/settings/payroll-settings',
            active: false,
          },
          {
            name: 'Approval Settings',
            id: 'approval-settings',
            icon: HomeSVG,
            route: '/settings/approval-settings',
            active: false,
          },
          {
            name: 'Plans & Billings',
            id: 'plans-billings',
            icon: HomeSVG,
            route: '/settings/plans-billings',
            active: false,
          },
          {
            name: 'Integrations',
            id: 'integrations',
            icon: HomeSVG,
            route: '/settings/integrations',
            active: false,
          },
        ]
      },
      {
        name: 'Referrals',
        id: 'referrals',
        icon: ReferrralSVG,
        status: 'new',
        route: '/referrals',
      },
      {
        name: 'Logout',
        icon: LogoutSVG,
        route: '/logout',
      },
    ],
  },
  {
    name: 'spend management',
    entryRoute: '/dashboard',
    sidebarItems: [
      {
        name: 'Dashboard',
        icon: HomeSVG,
        route: '/dashboard',
        active: true,
      },
      {
        name: 'Vendors',
        icon: VendorSVG,
        route: '/vendors',
      },
      {
        name: 'Customers',
        icon: CustomerSVG,
        route: '/customers',
      },
      {
        name: 'Purchase Airtime',
        icon: AirtimeSVG,
        route: '/airtime',
      },
      // {
      //   name: 'Reports',
      //   id: 'reports',
      //   icon: DocumentSVG,
      //   route: '/spend-reports',
      // },
      {
        name: 'Wallet',
        icon: WalletSVG,
        route: '/wallet',
      },
      {
        name: 'Settings',
        icon: SettingsSVG,
        setsubroute: false,
        subroute: [
          {
            name: 'Admin Management',
            id: 'admin-management',
            icon: HomeSVG,
            route: '/settings/admin-management',
            active: false,
          },
          {
            name: 'Company Information',
            id: 'company-information',
            icon: HomeSVG,
            route: '/settings/company-information',
            active: false,
          },
          {
            name: 'Approval Settings',
            id: 'approval-settings',
            icon: HomeSVG,
            route: '/settings/approval-settings',
            active: false,
          },
          {
            name: 'Plans & Billings',
            id: 'plans-billings',
            icon: HomeSVG,
            route: '/settings/plans-billings',
            active: false,
          },
          {
            name: 'Integrations',
            id: 'integrations',
            icon: HomeSVG,
            route: '/settings/integrations',
            active: false,
          },
        ]
      },
      {
        name: 'Referrals',
        id: 'referrals',
        icon: ReferrralSVG,
        status: 'new',
        route: '/referrals',
      },
      {
        name: 'Logout',
        icon: LogoutSVG,
        route: '/logout',
      },

    ],
  },
];
